.sankey-chart {
  .chart-tooltip {
    &::before {
      position: absolute;
      content: '';
      top: 0;
      left: calc(50% - 8px);
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 4px 6px 4px;
      border-color: transparent transparent #244357 transparent;
      transform: translate3d(50%, -6px, 0);
    }

    &.left::before {
      left: 5px;
    }

    &.right::before {
      left: calc(100% - 20px);
    }
  }

  .node-service {
    rect {
      fill: #CEECF8;
    }

    &.active {
      rect {
        fill: #244357;
      }

      text {
        fill: #FFF;
      }
    }
  }
}
