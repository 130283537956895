// Hide the Mapbox logo
.mapboxgl-ctrl-logo {
  display: none !important;
}

.legend-depth {
  position: absolute;
  bottom: 1rem;
  left: 1rem;
}

.legend-scale {
  position: absolute;
  top: 1rem;
  left: 1rem;
}

.legend-coords {
  position: absolute;
  top: 1rem;
  left: 50%;
  transform: translateX(-50%);
}

.legend-layers {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
}
