.bg-view-screen {
  animation: moving-background 1s linear infinite;
  background-repeat: repeat;
}

@keyframes moving-background {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 31px 62px; // must be 31xn
  }
}

.bg-video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% * 16/15);
}


.collector {
  background-color: white;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  border-width: 2px;
  border-color: #B62C8C;
  animation: ripple 1s linear infinite;
  position: relative;
  z-index: 10;
}

$color: #B62C8C;
@keyframes ripple {
  0% {
    box-shadow:
      0 0 0 0 rgba($color, 0.3),
      0 0 0 12px rgba($color, 0.3),
      0 0 0 18px rgba($color, 0.3),
      0 0 0 24px rgba($color, 0.3);
  }
  100% {
    box-shadow:
      0 0 0 12px rgba($color, 0.3),
      0 0 0 18px rgba($color, 0.3),
      0 0 0 24px rgba($color, 0.3),
      0 0 0 30px rgba($color, 0);
  }
}

.mine-path {
  height: 166px;
  width: 2px;
  display: block;
  position: absolute;
  background-color: #802C8C;
  transform: rotate(-15deg);
}

.marker-mine-site {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background-color: #E32F45;
}

.mapboxgl-ctrl-scale {
  height: 40px;
  background-color: #efefef;
  margin-top: 1rem !important;
  margin-left: 1rem !important;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  min-width: 280px;
  position: relative;
  font-size: 14px;
  display: flex;
  justify-content: space-between;

  &:before {
    content: 'Scale:  0';
  }
  &:after {
    content: '';
    height: 3px;
    width: 190px;
    display: block;
    background-color: black;
    position: absolute;
    bottom: 8px;
    right: 25px;

  }
}
