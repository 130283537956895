.plus-button {
  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    border-left: 2px solid #000;
    left: 22px;
    top: 13px;
    transition: all .2s;
  }

  &::after {
    left: 13px;
    top: 22px;
    transform: rotate(90deg);
  }

  &.open {
    &::before {
      opacity: 0;
      transform: rotate(90deg) translate(9px, 9px);
    }
  }
}

.line-separator {
  top: 0;
  left: 1rem;
  transform: translateY(-17px);
}
