.depth-spectrum {
  height: 4px;
  width: 190px;

  background: linear-gradient(90deg, #CCBFA7 0%, #CEE8FE 51.56%, rgba(255, 255, 255, 0) 100%), #B09AE8;
  border-radius: 35px;
}

.scale-spectrum {
  height: 4px;
  width: 190px;
}
