.levels-chart {
  .present {
    transform: translate(-22px, 25px);
  }

  .level-1 {
    text-shadow: 0 0 8px theme('colors.lime.50'),
      2px 0 9px theme('colors.lime.50'),
      0 0 10px theme('colors.lime.50'),
      0 0 10px theme('colors.lime.50');
  }

  .level-2 {
    text-shadow: 0 0 8px theme('colors.amber.50'),
      2px 0 9px theme('colors.amber.50'),
      0 0 10px theme('colors.amber.50'),
      0 0 10px theme('colors.amber.50');
  }

  .level-3 {
    text-shadow: 0 0 8px theme('colors.red.50'),
      2px 0 9px theme('colors.red.50'),
      0 0 10px theme('colors.red.50'),
      0 0 10px theme('colors.red.50');
  }
}
