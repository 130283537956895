@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'Swiss';
    src: url('../fonts/swiss/Swiss721BT-Light.eot');
    src: url('../fonts/swiss/Swiss721BT-Light.eot?#iefix') format('embedded-opentype'),
    url('../fonts/swiss/Swiss721BT-Light.woff2') format('woff2'),
    url('../fonts/swiss/Swiss721BT-Light.woff') format('woff'),
    url('../fonts/swiss/Swiss721BT-Light.ttf') format('truetype'),
    url('../fonts/swiss/Swiss721BT-Light.svg#Swiss721BT-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Swiss';
    src: url('../fonts/swiss/Swiss721BT-Regular.eot');
    src: local('Swiss721 BT'), local('Swiss721BT-Regular'),
    url('../fonts/swiss/Swiss721BT-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/swiss/Swiss721BT-Regular.woff2') format('woff2'),
    url('../fonts/swiss/Swiss721BT-Regular.woff') format('woff'),
    url('../fonts/swiss/Swiss721BT-Regular.ttf') format('truetype'),
    url('../fonts/swiss/Swiss721BT-Regular.svg#Swiss721BT-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Swiss';
    src: url('../fonts/swiss/Swiss721BT-Medium.eot');
    src: local('Swiss 721 Medium BT'), local('Swiss721BT-Medium'),
    url('../fonts/swiss/Swiss721BT-Medium.eot?#iefix') format('embedded-opentype'),
    url('../fonts/swiss/Swiss721BT-Medium.woff2') format('woff2'),
    url('../fonts/swiss/Swiss721BT-Medium.woff') format('woff'),
    url('../fonts/swiss/Swiss721BT-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Swift';
    src: url('../fonts/swift/SwiftRegular_43151.eot');
    src: url('../fonts/swift/SwiftRegular_43151.eot') format('embedded-opentype'),
    url('../fonts/swift/SwiftRegular_43151.woff2') format('woff2'),
    url('../fonts/swift/SwiftRegular_43151.woff') format('woff'),
    url('../fonts/swift/SwiftRegular_43151.ttf') format('truetype'),
    url('../fonts/swift/SwiftRegular_43151.svg#SwiftRegular_43151') format('svg');
    font-style: normal;
    font-display: swap;
  }

  html,
  body {
    min-height: 100%;
    height: 100%;
  }
}

.map-container {
  height: 500px;
  width: 100%;
}

a.arrow-link {
  position: relative;

  &::after {
    content: '';
    border: 1px solid theme('colors.blue.600');
    border-left: 0 none;
    border-bottom: 0 none;
    width: 6px;
    height: 6px;
    display: inline-block;
    transform: translate3d(2px, 0, 0) rotate(45deg);
  }
}

#tmc-app,
#tmc-app main {
  height: 100%;
}
