.es-info {
  &.function,
  &.indicator {
    h2 {
      &::before {
        content: '';
        width: 25px;
        height: 25px;
        margin-right: .75rem;
        display: inline-block;
        border-radius: 50%;
        transform: translate(0, 4px);
        background-color: theme('colors.lime.500');
      }
    }

    &.level-1 h2::before{
      background-color: theme('colors.lime.300');
    }

    &.level-2 h2::before{
      background-color: theme('colors.amber.400');
    }

    &.level-3 h2::before{
      background-color: theme('colors.red.600');
    }
  }
}
